import React, { Component } from 'react';
import Link from '../../components/Link';
import classnames from 'classnames';

class VenueHeader extends Component {
  state = {
    animate: false
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        animate: true
      })
    }, 700);
  }

  render() {
    const heroClasses = classnames(
      'venue__header__bgImg', {
        'venue__header__bgImg--animate': this.state.animate
      }
    )

    return (
      <header className="venue__header">
        <div className="venue__header__container">
          <div className="venue__header__container--inner">
            <Link to="/our-partners">
              <svg className="icon-back icon-back--large">
                <use xlinkHref="#back-arrow" href="#back-arrow"></use>
              </svg>
              <svg className="icon-back icon-back--small">
                <use xlinkHref="#arrow-left-small--red" href="#arrow-left-small--red"></use>
              </svg>
            </Link>
            <h1 className="venue__header__title t-pageTitle">
              {this.props.name}
            </h1>
            <p className="venue__header__location t-tag--large">
              {this.props.location}
            </p>
          </div>
        </div>

        <div className="venue__header__img">
          <div
            className={heroClasses}
            style={{
              backgroundImage: `url(${this.props.hero.url})`
            }}
          />
        </div>

        <div className="venue__overview">
          <div className="venue__overview__container">
            <div className="venue__overview__container--inner">
              <h2 className="venue__overview__title t-sectionTitle--small">
                Overview
              </h2>
              <p className="venue__overview__intro t-intro">
                {this.props.overview}
              </p>
              {
                this.props.cta !== null &&
                <Link to={this.props.url} key="external" className="venue__overview__link link link--underline t-tag--large">
                  {this.props.cta}
                  <svg className="icon-window">
                    <use xlinkHref="#window" href="#window"></use>
                  </svg>
                </Link>
              }
              {
                this.props.image360 !== null &&
                <Link
                  to={`/image360/${this.props.image360.sys.id}`}
                  key="360"
                  className="venue__360ViewCtaContainer t-cta--link"
                >
                  <div
                    className="venue__360ViewCta"
                    style={{
                      backgroundImage: `url(${this.props.image360.url}?w=200)`
                    }}
                  >
                    <div className="venue__360ViewCta__text">
                      <svg className="icon-360">
                        <use xlinkHref="#icon-360" href="#icon-360"></use>
                      </svg>
                      See 360&deg; view
                      <svg className="icon-caret-right">
                        <use xlinkHref="#caret-right" href="#caret-right"></use>
                      </svg>
                    </div>
                  </div>
                </Link>
              }
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default VenueHeader;