import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import Link from '../../components/Link';
import ogImg from '../../assets/img/og.jpg';

class ContactUs extends Component {

  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      validatedfirstName: "",
      lastName: "",
      validatedlastName: "",
      company: "",
      validatedcompany: "",
      phoneNumber: "",
      validatedphoneNumber: "",
      email: "",
      validatedemail: "",
      interests: [],
      formCompleted: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  regex = "[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?";

  handleChange(event) {
    const target = event.target;
    const value = target.type = target.value;
    const name = target.name;

    if (value.length > 0) {
      this.setState({
        ["validated" + name]: "validated"
      });
    } else {
      this.setState({
        ["validated" + name]: ""
      });
    }

    this.setState({
      [name]: value
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    var interests = Array.prototype.slice.call(document.querySelectorAll('input[type="checkbox"]:checked'));
    var interestsString = "";

    interests.map(function (interest) {
      this.interests.push(interest.name);
      if(interestsString.length > 1) {
        interestsString = interestsString + "|" + interest.name;
      } else {
        interestsString = interest.name;
      }
      return this;
    }, this.state);
    fetch('https://go.pardot.com/l/76542/2017-12-05/3tth87', {
      method: 'POST',
      mode: 'no-cors',
      headers: {
        'content-type': 'application/x-www-form-urlencoded'
      },
      credentials: 'include',
      body: 'email=' + this.state.email + '&company=' + this.state.company + '&phoneNumber=' + this.state.phoneNumber + '&lastName=' + this.state.lastName + '&firstName=' + this.state.firstName+ '&interests=' + interestsString
    });
    fetch('https://chicagocubs.my.salesforce-sites.com/mseForm/services/apexrest/mseForm', {
      method: 'POST',
      mode: 'no-cors',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify({
        lastName: this.state.lastName,
        firstName: this.state.firstName,
        company: this.state.company,
        phoneNumber: this.state.phoneNumber,
        email: this.state.email,
        interests: interestsString,
        aaccwebsite: false
      })
    });

    this.setState({ formCompleted: true });
  }

  render() {
    if (this.props.pageContactUsQuery && this.props.pageContactUsQuery.loading) {
      return (<div className="u-hidden">Loading</div>)
    } else if (this.props.pageContactUsQuery && this.props.pageContactUsQuery.error) {
      return (<div className="u-hidden">Error</div>)
    } else {
      this.data = this.props.pageContactUsQuery.thisContactUs[0];

      if (!this.data) {
        window.location.href = "/404";
        return (null);
      } else {

        if (!this.state.formCompleted) {
          return (
            <main className="contact-us">
              <Helmet>
                <title>{this.data.contactIntroHeader}</title>
                <meta name="description" content={this.data.contactIntroBody}/>
                <meta property="og:title" content={this.data.contactIntroHeader}/>
                <meta property="og:description" content={this.data.contactIntroBody}/>
                <meta property="og:image" content={ogImg}/>
                <meta property="og:url" content={window.location.href}/>
              </Helmet>

              <section className="form__area">
                <div className="form__area__container">
                  <h1 className="form__area__header__title t-pageTitle">{this.data.contactIntroHeader}</h1>
                  <h2 className="form__area__header__description t-copy">{this.data.contactIntroBody}</h2>
                  <form className="contact-us__form" onSubmit={this.handleSubmit} >
                    <div className="form-group">
                      <input
                        id="firstName"
                        className={this.state.validatedfirstName}
                        name="firstName"
                        type="text"
                        required="required"
                        value={this.state.firstName}
                        onChange={this.handleChange}
                      />
                      <span className="bar"></span>
                      <label className="control-label" htmlFor="firstName">
                        First Name*
                      </label>
                    </div>
                    <div className="form-group">
                      <input
                        id="lastName"
                        className={this.state.validatedlastName}
                        name="lastName"
                        type="text"
                        required="required"
                        value={this.state.lastName}
                        onChange={this.handleChange}
                      />
                      <span className="bar"></span>
                      <label className="control-label" htmlFor="lastName">
                        Last Name*
                      </label>
                    </div>
                    <div className="form-group">
                      <input
                        id="email"
                        className={this.state.validatedemail}
                        type="text"
                        pattern={this.regex}
                        title="user@domain.com"
                        name="email"
                        required="required"
                        value={this.state.email}
                        onChange={this.handleChange}/>
                      <span className="bar"></span>
                      <label className="control-label" htmlFor="email">
                        Email*
                      </label>
                    </div>
                    <div className="form-group">
                      <input
                        id="company"
                        className={`optional ${this.state.validatedcompany}`}
                        name="company"
                        type="text"
                        value={this.state.company}
                        onChange={this.handleChange}/>
                      <span className="bar"></span>
                      <label className="control-label" htmlFor="company">
                        Company
                      </label>
                    </div>
                    <div className="form-group">
                      <input
                        id="phoneNumber"
                        className={`optional ${this.state.validatedphoneNumber}`}
                        name="phoneNumber"
                        type="tel"
                        value={this.state.phoneNumber}
                        onChange={this.handleChange}
                      />
                      <span className="bar"></span>
                      <label className="control-label" htmlFor="phoneNumber">
                        Phone number
                      </label>
                    </div>
                    <div className="interest__area">
                      <span className="t-copy interest__header">{this.data.interestHeader}</span>
                      <div className="interests">
                        {
                          this.data.interests.map((interest, i) => (
                            <div className="cbContainer" key={interest}>
                              <input id={interest} name={interest}
                                type="checkbox"/>
                              <label htmlFor={interest}>
                                <span className="text t-tag--large">{interest}</span>
                              </label>
                            </div>
                          ))
                        }
                      </div>
                    </div>
                    <button className="button btnSubmit" type="submit" value="Submit">{this.data.buttonCTA}</button>
                  </form>
                </div>
              </section>
            </main>
          )
        } else {
          return (
            <main className="contact-us completed">
              <Helmet>
                <title>{this.data.contactIntroHeader}</title>
                <meta name="description" content={this.data.contactIntroBody}/>
              </Helmet>
              <section className="form__area">
                <h1 className="form__area__header__title t-pageTitle">{this.data.contactSuccessHeader}</h1>
                <h2 className="form__area__header__description t-copy">{this.data.contactSuccessBody}</h2>

                <Link to="/" className="button">
                  {this.data.contactSuccessButtonCta}
                </Link>
              </section>
            </main>
          )
        }
      }
    }
  }
}

export default ContactUs;