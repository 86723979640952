import React, { Component } from 'react';
import Link from '../../components/Link';

import CaseStudiesList from '../../components/services/CaseStudiesList';

class ServiceFooter extends Component {

  render() {
    if (this.props.footerCaseStudies) {
      return (
        <footer className="serviceFooter withCaseStudies">
          <div className="serviceFooter__container">
            <div className="serviceFooter__container--inner withCaseStudies">
              <h2 className="t-sectionTitle--large">Highlights</h2>
              <CaseStudiesList data={this.props.footerCaseStudies} showMoreLink={false} />
            </div>
          </div>
        </footer>
      )
    } else if (this.props.footerNextService !== undefined && this.props.footerNextService !== null) {
      return (
        <footer className="serviceFooter">
          <div className="serviceFooter__container">
            <div className="serviceFooter__container--inner">
              {
                (this.props.footerNextService.hero !== undefined && this.props.footerNextService.hero !== null) &&
                <div className="serviceFooter__imgContainer">
                  <img src={this.props.footerNextService.hero.url + "?w=480&h=320"} alt=""/>
                </div>
              }
              <div className="serviceFooter__column--right">
                <h2 className="t-sectionTitle--large">
                  {this.props.footerNextService.name}
                </h2>
                <p className="serviceFooter__copy t-copy">
                  {this.props.footerNextService.introBody}
                </p>
                <Link to={this.props.footerNextService.route} className="t-cta--link link">
                  Learn More
                </Link>
              </div>
            </div>
          </div>
        </footer>
      )
    } else if (this.props.footerVenuesLink) {
      return (
        <footer className="serviceFooter">
          <div className="serviceFooter__container">
            <div className="serviceFooter__container--inner">
              <div className="serviceFooter__imgContainer">
                <img src={this.props.footerVenuesLink.marketingImage.url + "?w=480&h=320"} alt=""/>
              </div>
              <div className="serviceFooter__column--right">
                <h2 className="t-sectionTitle--large">
                  {this.props.footerVenuesLink.introHeader}
                </h2>
                <p className="serviceFooter__copy t-copy">
                  {this.props.footerVenuesLink.introBody}
                </p>
                <Link to={"/"+this.props.footerVenuesLink.route} className="t-cta--link link">
                  Learn More
                </Link>
              </div>
            </div>
          </div>
        </footer>
      )
    } else {
      return (null)
    }
  }
}

export default ServiceFooter;