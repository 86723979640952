import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

import ServiceHeader from '../../components/services/ServiceHeader';
import ServiceFooter from '../../components/services/ServiceFooter';
import CaseStudiesList from '../../components/services/CaseStudiesList';
import CaseStudyStats from '../../components/case-studies/CaseStudyStats';
import ModuleQuote from '../../components/shared/ModuleQuote';
import ModuleGallery from '../../components/shared/ModuleGallery';
import ModuleVideo from '../../components/shared/ModuleVideo';
import ModuleChefs from '../../components/shared/ModuleChefs';
import ModuleConcerts from '../../components/shared/ModuleConcerts';
import ModuleFeatures from '../../components/shared/ModuleFeatures';
import VenueAside from '../../components/venues/VenueAside';

import Footer from '../../components/shared/Footer';

class Service extends Component {

  render() {
    if (this.props.pageServiceQuery && this.props.pageServiceQuery.loading) {
      return <div className="u-hidden">Loading</div>
    } else if (this.props.pageServiceQuery && this.props.pageServiceQuery.error) {
      return <div className="u-hidden">Error</div>
    } else {
      this.data = this.props.pageServiceQuery.thisService[0];

      if (!this.data) {
        window.location.href = "/404";
        return (null);
      } else {
        return (
          [
            <main key="Service" className="service">
              <Helmet>
                <title>{this.data.name}</title>
                <meta name="description" content={this.data.introBody}/>
                <meta property="og:title" content={this.data.name}/>
                <meta property="og:description" content={this.data.introBody}/>
                <meta property="og:url" content={window.location.href}/>
                <meta property="og:image" content={this.data.introBackground.url}/>
              </Helmet>

              <ServiceHeader
                background={this.data.introBackground.url}
                title={this.data.name}
                description={this.data.introBody}
              />
              {
              this.data.hero &&
                <section className="service__hero__container">
                  <div className="service_hero">
                    <img src={this.data.hero.url} alt=""/>
                    <span className="t-gallery-caption">{this.data.hero.description}</span>
                  </div>
                </section>
              }
              {
              this.data.caseStudies &&
                <CaseStudiesList data={this.data.caseStudies} showMoreLink={false} />
              }
              {
                this.data.bodySections &&
                this.data.bodySections.map((section, i) => {
                  switch (section.__typename) {
                    case 'ModuleStats':
                      return (
                        <CaseStudyStats
                          key={i}
                          body={section.body}
                          stats={section.stats}
                          textOnTop={section.textOnTop}
                        />
                      )
                    case 'ModuleQuote':
                      return (
                        <ModuleQuote
                          key={i}
                          body={section.body}
                          header={section.header}
                          quote={section.quote}
                        />
                      )
                    case 'ModuleGallery':
                      return (
                        <ModuleGallery
                          key={i}
                          images={section.images}
                          image360={section.image360}
                          header={section.header}
                          body={section.body}
                          above={section.headerBodyTextLocation}
                          linkText={section.linkText}
                          linkUrl={section.linkUrl}
                          fullWidthBottomImage={section.fullWidthBottomImage}
                          caption={section.caption}
                        />
                      )
                    case 'ModuleVideo':
                      return (
                        <ModuleVideo
                          key={i}
                          header={section.header}
                          video={section.videoUrl}
                        />
                      )
                    case 'ModuleChefs':
                      return (
                        <ModuleChefs
                          key={i}
                          header={section.header}
                          body={section.body}
                          chefs={section.chefs}
                        />
                      )
                    case 'ModuleConcerts':
                      return (
                        <ModuleConcerts
                          key={i}
                          headliners={section.headliners}
                          images={section.images}
                          supportingActs={section.supportingActs}
                        />
                      )
                    case 'ModuleFeatures':
                      return (
                        <ModuleFeatures
                          key={i}
                          header={section.header}
                          body={section.body}
                          features={section.features}
                        />
                      )
                    case 'ModuleAside':
                      return (
                        <VenueAside
                          key={i}
                          header={section.header}
                          body={section.body}
                          bulletPoints={section.bulletPoints}
                        />
                      )
                    default:
                      return (
                        <div key={i}/>
                      )
                    }
                  }
                )
              }
              <ServiceFooter {...this.data} />
            </main>,
            <Footer key="Footer" mainNavigation={this.props.pageServiceQuery.footer[0].mainNavigation} contactUsPage={this.props.pageServiceQuery.footer[0].contactUsPage} />
          ]
        );
      }
    }
  }
}

export default Service;