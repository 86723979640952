import React, { Component } from 'react';
import Link from '../../components/Link';
import { graphql } from '@apollo/client/react/hoc';
import {gql} from '@apollo/client';
import classnames from 'classnames';

const NAV_QUERY = gql `
  query NavQuery {
    globalSites {
      mainNavigation {
        primaryNavigationLinks {
          __typename
          ... on PageOurVenues {
            route
            navigationName
          }
          __typename
          ... on PageWhatWeDo {
            route
            navigationName
          }
          __typename
          ... on PageWhoWeAre {
            route
            navigationName
          }
        }
        buttonCTA
        phoneNumber
      }
      contactUsPage {
        route
      }
    }
  }
`;

class Navbar extends Component {

  data = {};

  state = {
    isMenuOpen: false,
    isNavVisible: false
  }

  openMenu = () => {
    this.setState({
      isMenuOpen: !this.state.isMenuOpen
    });
  }

  closeMenu = (str) => {
    setTimeout(() => {
      this.setState({
        isMenuOpen: false
      });
    }, 300)
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const offset = window.pageYOffset || document.documentElement.scrollTop;
    const hp = window.innerHeight / 2;

    if (offset < hp) {
      this.setState({
        isNavVisible: false
      });
    } else {
      this.setState({
        isNavVisible: true
      });
    }
  }

  pathIsExactly = (str) => {
    return window.location.pathname === str;
  }

  pathIncludes = (str) => {
    return window.location.pathname.includes(str) && !this.pathIsExactly(str);
  }

  render() {

    const menuClasses = classnames({
      'menu': true,
      'is-open': this.state.isMenuOpen,
      'and-navIsVisible': this.state.isNavVisible,
    });

    const staticNavLogo = classnames({
      'navbar__logo': true,
      'is-hidden': this.pathIsExactly('/'),
    })

    const staticNavClasses = classnames({
      'navbar__container': true,
      'is-open': this.state.isMenuOpen,
      'is-on-white': this.pathIncludes('/our-venues/')      ||
                     this.pathIncludes('/who-we-are/')      ||
                     this.pathIncludes('/case-studies/')    ||
                     this.pathIncludes('/privacy-policy')   ||
                     this.pathIncludes('/terms-of-service') ||
                     this.pathIsExactly('/404'),
    });

    const fixedNavClasses = classnames({
      'navbar__container': true,
      'is-fixed': true,
      'is-open': this.state.isMenuOpen,
      'is-visible': this.state.isNavVisible,
    });

    const logoLink = this.pathIncludes('/invoice/') ?  window.location.pathname : '/';

    const invoiceNavClasses = classnames({
      'is-hidden' : this.pathIncludes('/invoice/')
    });

    if (window.location.pathname.includes('image360')) {
      return (
        <div className="u-hidden"></div>
      )
    } else {
      if (this.props.navQuery && this.props.navQuery.loading) {
        return <div className="u-hidden">Loading</div>
      } else if (this.props.navQuery && this.props.navQuery.error) {
        return <div className="u-hidden">Error</div>
      } else {
        this.data = this.props.navQuery.globalSites[0];
        return (
          <nav className="navbar">
            <div className={staticNavClasses}>
              <div className="navbar__container--inner">
                <Link to={logoLink}>
                  <svg className={staticNavLogo}>
                    <use xlinkHref="#logo" href="#logo"></use>
                  </svg>
                  <svg className={`${staticNavLogo} halo`}>
                    <use xlinkHref="#logo_halo" href="#logo_halo"></use>
                  </svg>
                </Link>
                <Link to={"/" + this.data.contactUsPage.route} className={`t-nav navbar__cta ${invoiceNavClasses}`} onClick={this.closeMenu}>
                  {this.data.mainNavigation.buttonCTA}
                </Link>
                <div
                  className={`navbar__hamburger ${invoiceNavClasses}`}
                  onClick={this.openMenu}>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </div>
            <div className={fixedNavClasses}>
              <div className="navbar__container--inner">
                <Link to="/">
                  <svg className="navbar__logo">
                    <use xlinkHref="#logo" href="#logo"></use>
                  </svg>
                  <svg className="navbar__logo halo">
                    <use xlinkHref="#logo_halo" href="#logo_halo"></use>
                  </svg>
                </Link>
                <Link to={"/" + this.data.contactUsPage.route} className="t-nav navbar__cta" onClick={this.closeMenu}>
                  {this.data.mainNavigation.buttonCTA}
                </Link>
                <div
                  className="navbar__hamburger navbar__hamburger--fixed"
                  onClick={this.openMenu}>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </div>
            <div className={menuClasses}>
              <div className="menu__container">
                {/*<svg className="menu__marquee">
                  <use xlinkHref="#marquee-menu" href="#marquee-menu"></use>
                </svg>*/}

                <div className="menu__navContainer">
                  <Link to="/" onClick={this.closeMenu} className="menu__logoContainer">
                    <svg className="menu__logo">
                      <use xlinkHref="#logo" href="#logo"></use>
                    </svg>
                    <svg className="menu__logo halo">
                      <use xlinkHref="#logo_halo" href="#logo_halo"></use>
                    </svg>
                  </Link>
                  <div
                    className="menu__hamburger"
                    onClick={this.openMenu}
                  >
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>

                <ul className="menu__items">
                  {this.data.mainNavigation.primaryNavigationLinks.map((link, i) => (
                    <li className="menu__item t-pageTitle" key={i}>
                      <Link to={`/${link.route}`} className="menu__link" onClick={this.closeMenu}>
                        {link.navigationName}
                      </Link>
                    </li>
                  ))}
                </ul>

                <div className="menu__contact">
                  <Link to={"/" + this.data.contactUsPage.route} className="t-tag--large button menu__cta" onClick={this.closeMenu}>
                    {this.data.mainNavigation.buttonCTA}
                  </Link>
                  {
                    this.data.mainNavigation.phoneNumber &&

                    <p className="t-tag--large">
                      or call <a href={"tel:" + this.data.mainNavigation.phoneNumber} className="menu__phone">{this.data.mainNavigation.phoneNumber}</a>
                    </p>
                  }
                </div>
              </div>
            </div>
          </nav>
        )
      }
    }
  }
}

export default graphql(NAV_QUERY, { name: 'navQuery' })(Navbar);