import React, { Component } from 'react';

class ModuleConcerts extends Component {
  state = {
    manipulatedActs: []
  }

  componentDidMount() {
    this.setState({
      manipulatedActs: this.manipulateArray(this.props.supportingActs)
    });
  }

  manipulateArray = (arr) => {
    let mA = [];
    let row = [];
    let rowLength = 0;

    for (let item of arr) {
      rowLength += item.length;
      if (item === arr[arr.length - 1]) {
        row.push(item);
        mA.push(row);
      } else if (rowLength <= 40) {
        row.push(item);
      } else {
        mA.push(row);
        rowLength = item.length;
        row = [];
        row.push(item);
      }
    }

    return mA;
  }

  render() {
    return (
      <section className="moduleConcerts">
        <div className="moduleConcerts__headliners">
          <ul className="moduleConcerts__headliners__container">
            {
              this.props.headliners.map((headliner, i) => {
                if (i % 2 === 0) {
                  return (
                    [
                      <li key={i} className="moduleConcerts__headliner">
                        {headliner}
                      </li>,
                      <li key={`divider-${i}`} className="moduleConcerts__divider" />
                    ]
                  )
                } else {
                  return (
                    <li key={i} className="moduleConcerts__headliner">
                      {headliner}
                    </li>
                  )
                }
              })
            }
          </ul>
        </div>
        <div className="moduleConcerts__images">
          {
            this.props.images.map((image, i) => (
              <div key={i} className="moduleConcerts__image">
                <div>
                  <img src={image.url} alt={image.title}/>
                </div>
                {
                  image.description &&
                    <span className="t-gallery-caption">{image.description}</span>
                }
              </div>
            ))
          }
        </div>
        <div className="moduleConcerts__supporting">
          <ul className="moduleConcerts__supporting__container">
            {
              this.state.manipulatedActs.map((manipulatedAct, i) => (
                <div key={i} className="moduleConcerts__supporting__row">
                  {
                    manipulatedAct.map((supportingAct, i, arr) => {
                      if (arr.length - 1 === i) {
                        return (
                          <li key={i} className="moduleConcerts__supportingAct">
                            {supportingAct}
                          </li>
                        )
                      } else {
                        return (
                          [
                            <li key={i} className="moduleConcerts__supportingAct">
                              {supportingAct}
                            </li>,
                            <li key={`divider-${i}`} className="moduleConcerts__divider" />
                          ]
                        )
                      }
                    })
                  }
                </div>
              ))
            }
          </ul>
        </div>
      </section>
    )
  }
}

export default ModuleConcerts;