import React, { Component } from 'react';
import CaseCard from '../shared/CaseCard';

class CaseStudyFooter extends Component {
  render() {
    return (
      <footer className="caseStudyFooter">
        <div className="caseStudyFooter__container">
          <div className="caseStudyFooter__container--inner">
            <h1 className="caseStudyFooter__title t-sectionTitle--large">
              More of our work
            </h1>
            <div className="caseStudyFooter__related">
              {
                this.props.footerCaseStudies.map((card, i) => (
                  <div className="caseStudyFooter__card" key={i}>
                    <CaseCard
                      img={card.marketingImage.url}
                      title={card.marketingImage.title}
                      label={card.name}
                      tags={card.relatedServices}
                      route={card.route}
                    />
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default CaseStudyFooter;