import React, { Component } from 'react';
import Link from '../../components/Link';

class ServiceHeader extends Component {
  render() {
    return (
      <header
        className="service__header"
        style={{
          backgroundImage: `url(${this.props.background+"?w="+window.innerWidth})`
        }}
      >
        <div className="service__header__container">
          <div className="service__header__container--inner">
            <Link
              to="/what-we-do"
              className="service__header__back"
            >
              <svg className="icon-back icon-back--large">
                <use xlinkHref="#arrow-left-large" href="#arrow-left-large"></use>
              </svg>
              <svg className="icon-back icon-back--small">
                <use xlinkHref="#arrow-left-small--white" href="#arrow-left-small--white"></use>
              </svg>
            </Link>
            <h1 className="service__header__title t-pageTitle">
              {this.props.title}
            </h1>
            <p className="service__header__copy t-copy">
              {this.props.description}
            </p>
          </div>
        </div>
      </header>
    );
  }
}

export default ServiceHeader;