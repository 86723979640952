import React, { Component } from 'react';
import Link from '../../components/Link';
import { Helmet } from 'react-helmet';
import ModalVideo from '../../components/ReactModalVideo';
import ServiceFooter from '../../components/services/ServiceFooter';
import Footer from '../../components/shared/Footer';

class PageWhatWeDo extends Component {

  componentWillMount() {
    var tArray = new Array(20); //more than you would expect there to ever be.
    tArray.fill(false);
    this.setState({ reelOpen: tArray });
  }

  playReel(index) {
    var reelOpen = this.state.reelOpen.slice();
    reelOpen.fill(false);
    reelOpen[index] = true;
    this.setState({ reelOpen: reelOpen });
  }

  closeVideo() {
    var reelOpen = this.state.reelOpen.slice();
    reelOpen.fill(false);
    this.setState({ reelOpen: reelOpen });
  }

  render() {
    if (this.props.pageWhatWeDoQuery && this.props.pageWhatWeDoQuery.loading) {
      return <div className="u-hidden">Loading</div>
    } else if (this.props.pageWhatWeDoQuery && this.props.pageWhatWeDoQuery.error) {
      return <div className="u-hidden">Error</div>
    } else {
      this.data = this.props.pageWhatWeDoQuery.thisPageWhatWeDo[0];

      if (!this.data) {
        window.location.href = "/404";
        return (null);
      } else {

        var heroStyle = {
          backgroundImage: `url(${this.data.introBackground.url + "?h=600&q=100&fm=jpg&fl=progressive"})`
        }
        return (
          [
            <main key="WhatWeDo" className="page__whatwedo">
              <Helmet>
                <title>{this.data.introHeader}</title>
                <meta name="description" content={this.data.introBody}/>
                <meta property="og:title" content={this.data.introHeader}/>
                <meta property="og:description" content={this.data.introBody}/>
                <meta property="og:url" content={window.location.href}/>
                <meta property="og:image" content={this.data.introBackground.url}/>
              </Helmet>

              {
                this.data.primaryServices.map(function(service, i) {
                  if (service.videoUrl) {
                    return <ModalVideo key={i} isOpen={this.state.reelOpen[i]} video={service.videoUrl} onClose={() => this.closeVideo()} />
                  } else {
                    return (null)
                  }
                }, this)
              }

              <header className="hero" style={heroStyle}>
                <div className="hero__container">
                  <div className="hero__content">
                    <h1 className="t-pageTitle">
                      {this.data.introHeader}
                    </h1>
                    <p className="hero__intro t-copy">
                      {this.data.introBody}
                    </p>
                    <ul className="hero__sections t-tag--large">
                      {
                        this.data.primaryServices.map((link, i) => (
                          <li key={i} className="hero__section">
                            <Link to={`/what-we-do/${link.servicePage.route}`} className="hero__anchor link link--underline">
                              {link.header}
                            </Link>
                          </li>
                        ))
                      }
                    </ul>
                  </div>
                </div>
              </header>

              <div className="sections">
                {
                  this.data.primaryServices.map((service, i) => (
                    <section key={i} className="section">
                      <div className="section__featuredImg">
                        <img src={`${service.hero.url}?fm=jpg&q=100&fl=progressive&h=500&w=1180&fit=fill`} alt="" />
                      </div>
                      <div className="section__counter"></div>
                      <div className="section__content">
                        <div className="section__content--left">
                          <h2 className="t-sectionTitle--large">
                            {service.header}
                          </h2>
                          {service.body && <p className="section__copy t-copy">
                            {service.body}
                          </p>}
                          <Link
                            to={`/what-we-do/${service.servicePage.route}`}
                          className="section__link t-cta--link link">
                            {service.linkText}
                          </Link>
                          {
                            service.videoUrl &&
                            <button onClick={() => this.playReel(i)} className="section__link t-cta--link link">Watch the video <svg className="icon-play"><use xlinkHref="#icon-play" href="#icon-play"></use></svg></button>
                          }
                        </div>
                        <div className="section__content--right">
                          {
                            service.quote &&
                            [
                            <q key="quote" className="section__quote t-quote">
                              <span className="quote__mark quote__ldquo">&ldquo;</span>
                              {service.quote.quote}
                              <span className="quote__mark quote__rdquo">&rdquo;</span>
                            </q>,
                            <p key="attribution" className="section__quote__attribution t-cardTitle">
                              {service.quote.attribution}
                              {
                                service.quote.relevance &&
                              `, ${service.quote.relevance}`
                              }
                            </p>
                            ]
                          }
                        </div>
                      </div>
                    </section>
                  ))
                }
              </div>
              {this.data.categoryList &&
                <div className="explore">
                  <h2 className="explore__title t-sectionTitle--large">{this.data.categoryHeader}</h2>
                  <div className="explore__links">
                    {
                      this.data.categoryList.map((category, i) => (
                        <Link key={i} to={"/what-we-do/" + category.servicePage.route} className="explore__link">
                          <img src={category.categoryImage.url} alt=""/>
                          <div className="explore__link__labelContainer">
                            <span className="explore__link__label t-cardTitle">
                              {category.header}
                            </span>
                          </div>
                        </Link>
                      ))
                    }
                  </div>
                </div>
              }

              <ServiceFooter
                img={this.data.venueImage.url}
                title={this.data.venueHeader}
                copy={this.data.venueBody}
                to="/our-venues"
              />
            </main>,
            <Footer key="Footer" mainNavigation={this.props.pageWhatWeDoQuery.footer[0].mainNavigation} contactUsPage={this.props.pageWhatWeDoQuery.footer[0].contactUsPage} />
          ]
        );
      }
    }
  }
}

export default PageWhatWeDo;