import React, { Component } from 'react';
import PlaidLink from 'react-plaid-link'
import { Helmet } from "react-helmet";
import ReactMarkdown from 'react-markdown';
import ogImg from '../../assets/img/og.jpg';
import StripeCheckout from 'react-stripe-checkout';
import NumericInput from 'react-numeric-input';
import CurrencyInput from 'react-currency-input';

class Invoice extends Component {

  constructor(props) {
    super(props);
    this.state = {
      recordLocator: props.match.params.matchedRoute,
      validatedrecordLocator: "validated",
      email: "",
      validatedemail: "",
      formCompleted: false,
      verifyStepCompleted: false,
      submitted: false,
      isHidden: true,
      isDeposits: false,
      venue: "",
      start_time: "",
      party_size: "",
      total_amount: "",
      total_due_stripe: "",
      opportunityID: "",
      plaid: false,
      credit_card: false,
      credit_card_completed: false,
      loadedText: "",
      total_paid_formatted: "",
      payment_id: ""

    };

    this.handleChange = this.handleChange.bind(this);
    this.handleAmountCurrencyChange =  this.handleAmountCurrencyChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleOnLoad = this.handleOnLoad.bind(this);
    this.handleOnCCLoad = this.handleOnCCLoad.bind(this);
    this.handleVerify = this.handleVerify.bind(this);
    this.handleCCVerify = this.handleCCVerify.bind(this);
    this.handleOnExit = this.handleOnExit.bind(this);
    this.handleOnCCExit = this.handleOnCCExit.bind(this);
    this.handleOnSuccess =  this.handleOnSuccess.bind(this);

  }

  regex = "[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?";
  regexNumber = "/^[0-9\b]+$/";

  handleChange(event) {
    const target = event.target;
    const value = target.type = target.value;
    const name = target.name;

    if (value.length > 0) {
      this.setState({
        ["validated" + name]: "validated"
      });
    } else {
      this.setState({
        ["validated" + name]: ""
      });
    }

    this.setState({
      [name]: value
    });
  }

  handleAmountCurrencyChange(event, maskedValue, value){

    const re = /^[0-9\b]+$/;
    if ((value !== '' || re.test(value)) && value <= this.state.total_amount && value >= .5) {
      
      this.setState({ remaining_balance: this.state.total_amount-value });
      this.setState({ remaining_balance_formatted: this.formatMoney(this.state.total_amount-value) });

      this.setState({ total_due: value});
      this.setState({ total_due_formatted: this.formatMoney(value) });
      this.setState({ total_due_stripe: Math.round((value*100)*(this.state.fee+1)) }); 
    } else {
      if(value > this.state.total_amount || value < 0){
        this.setState({ remaining_balance: 0 });
        this.setState({ remaining_balance_formatted: this.formatMoney(0) });

        this.setState({ total_due: this.state.total_amount});
        this.setState({ total_due_formatted: this.formatMoney(0) });
        this.setState({ total_due_stripe: Math.round((this.state.total_amount*100)*(this.state.fee+1)) }); 
      }
    }
  }

  handleSubmit(event) {
    event.preventDefault();

    this.setState({ submitted: true });
    this.setState({ isHidden: true , error: ""}); 

    var rest_url = 'https://chicagocubs.my.salesforce-sites.com/mseForm/services/apexrest/hsc/ach';
    if(this.props.pageInvoiceQuery.thisInvoicePage[0].plaidEnv === 'sandbox'){
      rest_url = 'https://chicagocubs--qa.sandbox.my.salesforce-sites.com/mseForm/services/apexrest/hsc/ach';
    }

    fetch(rest_url + '?email=' + this.state.email + '&invoiceNum=' + this.state.recordLocator, {
      method: 'GET',
      headers: {
        'Accept': 'application/json'
      },
    }).then(response => response.json())
      .then(result => {
        if(result.error){
          this.showError(result.error);
        } else {
          this.setState({ submitted: false });
          if(result.success){
            this.setState({ venue: result.venue });
            this.setState({ start_time: result.start_time });
            this.setState({ party_size: result.party_size });
            this.setState({ opportunityID: result.opportunityID });
            this.setState({ total_amount: result.total_amount-result.total_paid });
            this.setState({ total_paid: result.total_paid });
            this.setState({ total_paid_formatted: '(' + this.formatMoney(result.total_paid) + ')' });
            this.setState({ total_due: result.total_amount-result.total_paid });
            this.setState({ total_due_formatted: this.formatMoney(result.total_amount-result.total_paid) });
            this.setState({ total_amount_formatted: this.formatMoney(result.total_amount) });
            this.setState({ balance_formatted: this.formatMoney(result.total_amount) });
            this.setState({ remaining_balance: result.total_amount-result.total_amount });
            this.setState({ remaining_balance_formatted: this.formatMoney(result.total_amount-result.total_amount) });
            this.setState({ total_due_stripe: Math.round(((result.total_amount-result.total_paid) *100)*(result.fee+1)) });
            this.setState({ formCompleted: true });
            this.setState({ fee_formatted: (result.fee*100).toFixed(2) });
            this.setState({ fee: result.fee});
            this.setState({ loadedText: "loaded" });
          }
        }
      });
  }
  
  onToken = async (token) => {
    var self = this;
    
    this.setState({ loadedText: "loading" });
    
    var data = {
      'source' : token.id,
      'total_amount' : this.state.total_due_stripe,
      'venue' : this.state.venue,
      'metadata' : {
        'opportunityID' : this.state.opportunityID,
        'name' : this.state.opportunityID,
        'chargeRecordType' : 'Campus Event',
        'mseOnlinePayment' : true
      }
    };

    fetch('/exchangeStripeToken', {
      method : 'POST',
      headers: { 'Content-Type': 'application/json' },
      body : JSON.stringify(data)
    }).then(response => response.json())
      .then(data => {
        self.setState({ loadedText: "loaded" });
        self.setState({ credit_card: false });
        self.setState({ credit_card_completed: true });
        self.setState({ total_paid_formatted: this.formatMoney(this.state.total_due_stripe/100) });
        if(data.error){
          self.showError(data.error);
        } else {
          self.setState({ verifyStepCompleted: true, payment_id: data.stripe_payment_id });
        }
    });
  }

  handleCCVerify(event) {
    event.preventDefault();
    this.setState({ isHidden: true , error: ""}); 
    this.setState({ credit_card: true });

  }

  handleVerify(event) {
    event.preventDefault();

    this.setState({ isHidden: true , error: ""}); 
    this.setState({ plaid: true });

  }

  handleOnSuccess(token, metadata){
    
    this.setState({ plaid: false });
    var self = this;

    var data = {
      'public_token' : token,
      'account_id' : metadata.account_id,
      'total_amount' : this.state.total_due,
      'venue' : this.state.venue,
      'metadata' : {
        'opportunityID' : this.state.opportunityID,
        'chargeRecordType' : 'Campus Event',
        'mseOnlinePayment' : true
      }
    };

    fetch('/exchangeToken', {
      method : 'POST',
      headers: { 'Content-Type': 'application/json' },
      body : JSON.stringify(data)
    }).then(response => response.json())
      .then(data => {
        self.setState({ loadedText: "loaded" });
        self.setState({ total_paid_formatted: this.formatMoney(this.state.total_due) });
        if(data.error){
          self.showError(data.error);
        } else {
          self.setState({ verifyStepCompleted: true, payment_id: data.stripe_payment_id });
        }
    });

  }

  handleOnLoad(){
    window.linkHandler.open();
    this.setState({ loadedText: "loading" });
  }

  handleOnCCLoad(){
    this.setState({ loadedText: "loading" });
  }

  handleOnExit(error, metadata){
    this.setState({ loadedText: "loaded" });
    this.setState({ plaid: false });
    
    if (error != null) {
    }

    if (metadata.status === "institution_not_found") {    
      // $('.invoice-panel').slideUp(function() {
      //     $('.header').html('Manual Bank Verification');
      //     $('.overview').hide();
      //     $('.back').show();
      //     $('.manual-ach').show();
      //     $('.invoice-panel').slideDown();
      // });
    }
  }

  handleOnCCExit(error, metadata){
    var self = this;
    setTimeout(function () {
        self.setState({ loadedText: "loaded" });
        self.setState({ credit_card: false });
    }, 1500);
  }

  showError(message, isSubmitted){
    var errMessage = message || 'Something went wrong, try again.';

    this.setState({ isHidden: false , error: errMessage}); 
    this.setState({ submitted: false }); 
    this.setState({ plaid: false });

  }

  myformat(num){
    if (num) {
        var parts = num.split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        parts = parts.join(".");
        return "$" + parts;
    } else {
        return "$ --";
    }
  }

  formatMoney(value) {
    if (value) {
        var parts = value.toFixed(2).toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        parts = parts.join(".");
        return "$" + parts;
    } else {
        return "$ --";
    }
  }


  parse(stringValue) {
    return stringValue.replace(/^\$/, "").replace(/,/g,"");
  } 


  render() {
    if (this.props.pageInvoiceQuery && this.props.pageInvoiceQuery.loading) {
      return (<div className="u-hidden">Loading</div>)
    } else if (this.props.pageInvoiceQuery && this.props.pageInvoiceQuery.error) {
      return (<div className="u-hidden">Error</div>)
    } else {
      this.data = this.props.pageInvoiceQuery.thisInvoicePage[0];

      if (!this.data) {
        window.location.href = "/404";
        return (null);
      } else {

        if (!this.state.formCompleted) {
          var amountInputStyle = {
            paddingRight: 0
          };
          return (
            <main className="invoice">
              <Helmet>
                <title>{this.data.contactIntroHeader}</title>
                <meta name="description" content={this.data.invoiceIntroBody}/>
                <meta property="og:title" content={this.data.invoiceIntroHeader}/>
                <meta property="og:description" content={this.data.invoiceIntroBody}/>
                <meta property="og:image" content={ogImg}/>
                <meta property="og:url" content={window.location.href}/>
              </Helmet>

              <section className="form__area">
                <div className="form__area__container">
                  <h1 className="form__area__header__title t-pageTitle">{this.data.invoiceIntroHeader}</h1>
                  <h2 className="form__area__header__description t-copy">{this.data.invoiceIntroBody}</h2>
                  {!this.state.isHidden && <div className="submit-error">{this.state.error}</div>}
                  <form className="invoice__form" onSubmit={this.handleSubmit} >
                    {/*<div className="form-group">
                      <input
                        id="email"
                        className={this.state.validatedemail}
                        type="text"
                        pattern={this.regex}
                        title="user@domain.com"
                        name="email"
                        required="required"
                        value={this.state.email}
                        onChange={this.handleChange}/>
                      <span className="bar"></span>
                      <label className="control-label" htmlFor="email">
                        Email*
                      </label>
                    </div>*/}
                    <div className="form-group">
                      <input
                        id="recordLocator"
                        className={this.state.validatedrecordLocator}
                        name="recordLocator"
                        type="text"
                        required="required"
                        readOnly="readOnly"
                        value={this.state.recordLocator}
                        onChange={this.handleChange}
                      />
                      <span className="bar"></span>
                      <label className="control-label" htmlFor="recordLocator">
                        Payment Record Locator
                      </label>
                    </div>
                    <button ref="submitlookup" className="button btnSubmit" disabled={this.state.submitted} type="submit" value="Submit">{this.data.buttonCTA}</button>
                  </form>
                </div>
              </section>
            </main>
          )
        } else {
          if (!this.state.verifyStepCompleted) {
            return (
              <main className="invoice">
                <Helmet>
                  <title>{this.data.invoiceIntroHeader}</title>
                </Helmet>
                <section className="form__area">
                  <div className={`loader ${this.state.loadedText}`}>
                    <h2>Processing</h2>
                    <div className="progress">
                      <div className="indeterminate"></div>
                    </div>
                  </div>
                  <div className={`form__area__container verify ${this.state.loadedText}`}>
                    <h1 className="form__area__header__title t-pageTitle">{this.data.invoiceVerifyHeader}</h1>
                    {!this.state.isHidden && <div className="submit-error">{this.state.error}</div>}
                    <div className="invoice__form"  >
                      <div className="overview">
                          <table>
                            <tbody>
                              {this.state.isDeposits && <tr className="microdeposit-row"> <th scope="row">Deposit 1</th> <td> $<input id="deposit-1" className="deposits" type="text" placeholder="0.00" required/> </td> </tr> }
                              {this.state.isDeposits && <tr className="microdeposit-row"> <th scope="row">Deposit 2</th> <td> $<input id="deposit-2" className="deposits" type="text" placeholder="0.00" required/> </td> </tr>}
                              <tr>
                                  <th scope="row">Venue</th>
                                  <td>{this.state.venue}</td>
                              </tr>
                              <tr>
                                  <th scope="row">Date/Time</th>
                                  <td>
                                    {this.state.start_time}
                                  </td>
                              </tr>
                              <tr>
                                  <th scope="row">Party Size</th>
                                  <td>{this.state.party_size}</td>
                              </tr>
                              <tr>
                                  <th scope="row">Current Balance</th>
                                  <td>{this.state.balance_formatted}</td>
                              </tr>
                              {this.state.total_paid && 
                                <tr>
                                  <th scope="row">Amount Paid</th>
                                  <td>{this.state.total_paid_formatted}</td>
                                </tr>
                              }
                              <tr>
                                  <th scope="row">Amount To Pay</th>
                                  <td className="amountTd">
                                    <CurrencyInput id="totalAmount" name="totalAmount" className="totalAmount" selectAllOnFocus={true} precision={2} prefix="$" value={this.state.total_due} onChangeEvent={this.handleAmountCurrencyChange}/>
                                    <span className="bar"></span>
                                  </td>
                              </tr>
                              {this.state.remaining_balance > 0 && <tr>
                                  <th scope="row">Remaining  Balance</th>
                                  <td>{this.state.remaining_balance_formatted}</td>
                              </tr>}
                            </tbody>
                          </table>


                          <div className="legal">
                              <ReactMarkdown className="markdown-body" source={this.data.invoiceVerifyBody}/>
                          </div>
                          
                      </div> 
                      {this.state.total_due && this.state.total_due > 0 && 
                        <div className="buttonDiv">
                          {this.state.venue !== 'Wrigley Field' &&
                            <StripeCheckout
                               image={this.data.logo.url}
                               ComponentClass="div"
                               token={this.onToken}
                               stripeKey={this.data.stripePublicKey}
                               currency="USD"
                               opened={this.handleOnCCLoad}
                               closed={this.handleOnCCExit}
                               reconfigureOnUpdate={true}
                               allowRememberMe={false}
                               amount={this.state.total_due_stripe}
                               panelLabel={this.data.invoiceCcStripePayButtonText}
                             >
                                <button ref="submitverify" className="button btnSubmit verify" disabled={this.state.submitted} type="button" value="Submit" onClick={this.handleCCVerify}>{this.data.invoiceCcButtonCta}</button>
                             </StripeCheckout>
                          }
                          {this.state.venue === 'Wrigley Field' &&
                            <StripeCheckout
                               image={this.data.logo.url}
                               ComponentClass="div"
                               token={this.onToken}
                               stripeKey={this.data.stripePublicKeyCubs}
                               currency="USD"
                               opened={this.handleOnCCLoad}
                               closed={this.handleOnCCExit}
                               reconfigureOnUpdate={true}
                               allowRememberMe={false}
                               amount={this.state.total_due_stripe}
                               panelLabel={this.data.invoiceCcStripePayButtonText}
                             >
                                <button ref="submitverify" className="button btnSubmit verify" disabled={this.state.submitted} type="button" value="Submit" onClick={this.handleCCVerify}>{this.data.invoiceCcButtonCta}</button>
                             </StripeCheckout>
                          }
                       {/*   <div>
                            <button ref="submitverify" className="button btnSubmit verify" disabled={this.state.submitted} type="button" value="Submit" onClick={this.handleVerify}>{this.data.invoiceVerifyButtonCTA}</button>
                          </div>*/}
                         {/* {this.state.isDeposits && <button ref="submitdeposits" className="button btnSubmit verify" disabled={this.state.submitted} type="submit" value="Submit">{this.data.invoiceDepositsButtonCTA}</button>}
                          {this.state.plaid && this.state.venue !== 'Wrigley Field' && 
                            <PlaidLink
                              clientName="Marquee Sports and Entertainment LLC"
                              env={this.data.plaidEnv}
                              product={["auth"]}
                              publicKey={this.data.plaidPublicKey}
                              onExit={this.handleOnExit}
                              onSuccess={this.handleOnSuccess}
                              onLoad={this.handleOnLoad}>
                            </PlaidLink>
                          }
                          {this.state.plaid && this.state.venue === 'Wrigley Field' && 
                            <PlaidLink
                              clientName="Marquee Sports and Entertainment LLC"
                              env={this.data.plaidEnvCubs}
                              product={["auth"]}
                              publicKey={this.data.plaidPublicKeyCubs}
                              onExit={this.handleOnExit}
                              onSuccess={this.handleOnSuccess}
                              onLoad={this.handleOnLoad}>
                            </PlaidLink>
                          }*/}
                          <div className="fee">
                            {this.data.invoiceCcDisclosure}
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </section>
              </main>
            )
          } else {
            return (
              <main className="invoice">
                <Helmet>
                  <title>{this.data.invoiceIntroHeader}</title>
                </Helmet>
                <section className="form__area">
                  <div className="form__area__container verify">
                    <h1 className="form__area__header__title t-pageTitle">{this.data.invoiceSuccessHeader}</h1>
                    <div className="invoice__form"  >
                      <div className="overview">
                          <table>
                            <tbody>
                              <tr>
                                  <th scope="row">Reference Number</th>
                                  <td>{this.state.payment_id}</td>
                              </tr>
                              <tr>
                                  <th scope="row">Venue</th>
                                  <td>{this.state.venue}</td>
                              </tr>
                              <tr>
                                  <th scope="row">Date/Time</th>
                                  <td>
                                    {this.state.start_time}
                                  </td>
                              </tr>
                              <tr>
                                  <th scope="row">Party Size</th>
                                  <td>{this.state.party_size}</td>
                              </tr>
                              {this.state.credit_card_completed && 
                                <tr>
                                    <th scope="row">Subtotal</th>
                                    <td>{this.state.total_due_formatted}</td>
                                </tr>
                              }
                              {this.state.credit_card_completed && 
                                <tr>
                                    <th scope="row">Processing Fee</th>
                                    <td>{ this.formatMoney((this.state.total_due_stripe/100) - this.state.total_due)}</td>
                                </tr>
                              }
                              <tr>
                                  <th scope="row">Total</th>
                                  <td>{this.state.total_paid_formatted}</td>
                              </tr>
                            </tbody>
                          </table>

                          <div className="legal">
                            <ReactMarkdown className="markdown-body" source={this.data.invoiceSuccessBody}/>
                          </div>
                          
                      </div> 
                    </div>
                  </div>
                </section>
              </main>
            )
          }
        }
      }
    }
  }
}

export default Invoice;