import React, { Component } from 'react';
import Link from '../../components/Link';
import CaseCard from '../shared/CaseCard';

class CaseStudiesList extends Component {
  render() {

    this.rightCards = this.props.data.filter((v, i) => i % 2);
    this.leftCards = this.props.data.filter((v, i) => !(i % 2));

    return (
      <div className="caseStudies">
        <div className="caseStudies__content">
          <div className="caseStudies__column caseStudies__column--left">
            {
              this.leftCards.map((card, i) => {
                    var links = [];
                    if (card.venue) {
                      links.push({
                        name: card.venue.name,
                        route: `our-venues/${card.venue.route}`
                      });
                    }
                    card.relatedServices.forEach(function(s){
                      links.push(s)
                    });
                    return(
                      <CaseCard
                        key={i}
                        img={card.marketingImage.url}
                        title={card.marketingImage.title}
                        label={card.name}
                        tags={links}
                        route={card.route}
                      />
                    )
                })
              }
          </div>

          <div className="caseStudies__column caseStudies__column--right">
            {
              this.rightCards.map((card, i) => {
                    var links = [];
                    if (card.venue) {
                      links.push({
                        name: card.venue.name,
                        route: `our-venues/${card.venue.route}`
                      });
                    }
                    card.relatedServices.forEach(function(s){
                      links.push(s)
                    });
                    return(
                      <CaseCard
                        key={i}
                        img={card.marketingImage.url}
                        title={card.marketingImage.title}
                        label={card.name}
                        tags={links}
                        route={card.route}
                      />
                    )
                })
              }
          </div>
        </div>
        {
          this.props.showMoreLink !==false &&
          <div className="caseStudies__ctaContainer">
            <Link to="/what-we-do" className="caseStudies__cta button">See more work</Link>
          </div>
        }
      </div>
    );
  }
}

export default CaseStudiesList;