import React, {
  Component
} from 'react';
import classnames from 'classnames';

class CaseStudyStats extends Component {
  render() {
    const statsClasses = classnames(
      'caseStudyStats__stats', {
        'caseStudyStats__stats--long': this.props.stats.length > 5
      }
    )

    return (
      <section className="caseStudyStats">
        <div className="caseStudyStats__container">
          {
            this.props.body !== null &&
            <p className="t-copy caseStudyStats__copy">
              {this.props.body}
            </p>
          }
          <ul className={statsClasses}>
            {
              this.props.stats.map((stat, i) => (
                <li className="caseStudyStats__stat" key={i}>
                  {
                    this.props.textOnTop &&
                    <h3 className="caseStudyStats__stat__label t-sectionTitle--small">
                      {stat.description}
                    </h3>
                  }
                  <h4 className="caseStudyStats__stat__number t-number--large">
                    {stat.prefix}{stat.finalAmount}{stat.abbreviation}
                  </h4>
                  {
                    !this.props.textOnTop &&
                    <h3 className="caseStudyStats__stat__label t-sectionTitle--small">
                      {stat.description}
                    </h3>
                  }
                </li>
              ))
            }
          </ul>
        </div>
      </section>
    )
  }
}

export default CaseStudyStats;
