import React, { Component } from 'react';
import { Helmet } from "react-helmet";

import CaseStudyHeader from '../../components/case-studies/CaseStudyHeader';
import CaseStudyStats from '../../components/case-studies/CaseStudyStats';
import ModuleQuote from '../../components/shared/ModuleQuote';
import ModuleGallery from '../../components/shared/ModuleGallery';
import ModuleVideo from '../../components/shared/ModuleVideo';
import ModuleChefs from '../../components/shared/ModuleChefs';
import ModuleConcerts from '../../components/shared/ModuleConcerts';
import CaseStudyFooter from '../../components/case-studies/CaseStudyFooter';

import Footer from '../../components/shared/Footer';

class CaseStudy extends Component {

  render() {
    if (this.props.pageCaseStudyQuery && this.props.pageCaseStudyQuery.loading) {
      return <div className="u-hidden">Loading</div>
    } else if (this.props.pageCaseStudyQuery && this.props.pageCaseStudyQuery.error) {
      return <div className="u-hidden">Error</div>
    } else {
      this.data = this.props.pageCaseStudyQuery.thisCaseStudy[0];

      if (!this.data) {
        window.location.href = "/404";
        return (null);
      } else {
        return (
          [
            <main key="CaseStudy" className="case-study">
              <Helmet>
                <title>{this.data.name}</title>
                <meta name="description" content={this.data.overviewBody}/>
                <meta property="og:title" content={this.data.name}/>
                <meta property="og:description" content={this.data.overviewBody}/>
                <meta property="og:url" content={window.location.href}/>
                {
                  this.data.hero &&
                  <meta property="og:image" content={this.data.hero.url+"?fm=jpg&q=100&fl=progressive&w=1280&h=640&fit=fill"}/>
                }
              </Helmet>

              <CaseStudyHeader
                name={this.data.name}
                relatedServices={this.data.relatedServices}
                hero={this.data.hero}
                overviewBody={this.data.overviewBody}
                venue={this.data.venue}
                linkCta={this.data.linkCta}
                linkUrl={this.data.linkUrl}
                secondaryCalloutTitle={this.data.secondaryCalloutTitle}
                secondaryCalloutValue={this.data.secondaryCalloutValue}
                history={this.props.history}
              />

              {
                this.data.bodySections.map((section, i) => {
                  switch (section.__typename) {
                    case 'ModuleStats':
                      return (
                        <CaseStudyStats
                          key={i}
                          body={section.body}
                          stats={section.stats}
                          textOnTop={section.textOnTop}
                        />
                      )
                    case 'ModuleQuote':
                      return (
                        <ModuleQuote
                          key={i}
                          body={section.body}
                          header={section.header}
                          quote={section.quote}
                        />
                      )
                    case 'ModuleGallery':
                      return (
                        <ModuleGallery
                          key={i}
                          images={section.images}
                          image360={section.image360}
                          header={section.header}
                          body={section.body}
                          above={section.headerBodyTextLocation}
                          linkText={section.linkText}
                          linkUrl={section.linkUrl}
                          fullWidthBottomImage={section.fullWidthBottomImage}
                          caption={section.caption}
                        />
                      )
                    case 'ModuleVideo':
                      return (
                        <ModuleVideo
                          key={i}
                          header={section.header}
                          video={section.videoUrl}
                        />
                      )
                    case 'ModuleChefs':
                      return (
                        <ModuleChefs
                          key={i}
                          chefs={section.chefs}
                        />
                      )
                    case 'ModuleConcerts':
                      return (
                        <ModuleConcerts
                          key={i}
                          headliners={section.headliners}
                          images={section.images}
                          supportingActs={section.supportingActs}
                        />
                      )
                    default:
                      return (
                        <div key={i}/>
                      )
                    }
                  }
                )
              }

              <CaseStudyFooter
                footerCaseStudies={this.data.footerCaseStudies}
              />
            </main>,
            <Footer key="Footer" mainNavigation={this.props.pageCaseStudyQuery.footer[0].mainNavigation} contactUsPage={this.props.pageCaseStudyQuery.footer[0].contactUsPage} />
          ]
        );
      }
    }
  }
}

export default CaseStudy;