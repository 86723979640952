import React, { Component } from 'react';
import Link from '../../components/Link';

const { DateTime } = require('luxon');

class Footer extends Component {
  render() {
    return (
      <footer className="footer">
        <div className="footer__container">
          <div className="footer__container--inner">
            <h1 className="t-pageTitle">{this.props.mainNavigation.footerHeader}</h1>
            <p className="footer__copy t-copy">
              {this.props.mainNavigation.footerBody}
            </p>
            <div className="footer__ctaContainer">
              <Link to={"/" + this.props.contactUsPage.route} className="button footer__cta">
                {this.props.mainNavigation.buttonCTA}
              </Link>
              {
                this.props.mainNavigation.phoneNumber &&
                <span className="footer__numberContainer">or call <a href={"tel:" + this.props.mainNavigation.phoneNumber} className="footer__number">{this.props.mainNavigation.phoneNumber}</a></span>
              }
            </div>
          </div>
        </div>
        <div className="footer__footer">
          <div className="footer__footer__container">
            <div className="footer__footer--inner">
              <p className="t-caption">
                &copy; {DateTime.local().year} {this.props.mainNavigation.copyrightHolder}
              </p>
              <div className="footer__links t-caption">
                <a className="footer__link" href='javascript:revisitCkyConsent()'>Do Not Sell or Share My Personal Information</a>
                {
                  this.props.mainNavigation.footerLinks.map((link, i) => {
                    if (link.__typename === "ComponentFooterLink") {
                      return (
                        <Link key={i} to={`${link.route}`} className="footer__link">
                          {link.title}
                        </Link>
                      )
                    } else {
                      return (
                        <Link key={i} to={`/pages/${link.route}`} className="footer__link">
                          {link.title}
                        </Link>
                      )
                    }
                  })
                }
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer;