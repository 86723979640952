import React, { Component } from 'react';
import Link from '../../components/Link';

class VenueFooter extends Component {
  render() {
    return (
      <footer className="venueFooter">
        {
          this.props.footerImage &&
          <div className="venueFooter__img"
            style={{backgroundImage: `url(${this.props.footerImage.url+"?w="+window.innerWidth})`}}></div>
        }
        {
          this.props.pageVenues &&
          <div className="venueFooter__related">
            <div className="venueFooter__related__container">
              <div className="venueFooter__related__container--inner">
                <h2 className="t-sectionTitle--large">
                  Explore more partners
                </h2>
                <div className="venueFooter__related__venues">
                  {
                    this.props.pageVenues.map((venue, i) => (
                      <div key={i} className="venueFooter__related__venue">
                        <div className="venueFooter__related__venue__img">
                          <Link to={`/our-venues/${venue.route}`} className="venueFooter__related__venue__imgLink">
                            <img src={`${venue.marketingImage.url}?w=500`} alt=""/>
                          </Link>
                        </div>
                        <div className="venueFooter__related__venue__description">
                          <h3 className="venueFooter__related__venue__title t-cardTitle">
                            {venue.marketingDescription}
                          </h3>
                          <Link to={`/our-venues/${venue.route}`} className="t-cta--link link">
                            Learn More
                          </Link>
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>
          </div>
        }
      </footer>
    )
  }
}

export default VenueFooter;