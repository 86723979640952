import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import {gql} from '@apollo/client';

export default function invoiceWithData(WrappedComponent, options = {}) {
  const HOC = class extends Component {
    constructor(props) {
      super(props);
      this.wrapped = graphql(gql `
          query PageInvoiceQuery {
            thisInvoicePage: pageInvoices(q:"fields.route=invoice-page"){
              route
              invoiceIntroHeader
              invoiceIntroBody
              buttonCTA
              invoiceVerifyHeader
              invoiceVerifyBody
              invoiceVerifyButtonCTA
              invoiceCcButtonCta
              invoiceCcDisclosure
              invoiceCcStripePayButtonText
              invoiceDepositsButtonCTA
              invoiceSuccessHeader
              invoiceSuccessBody
              stripePublicKey
              plaidEnv
              plaidPublicKey
              stripePublicKeyCubs
              plaidEnvCubs
              plaidPublicKeyCubs
              logo {
                title
                description
                url
              }
            }
          }
      `, { name: 'pageInvoiceQuery' })(WrappedComponent);
    }

    render() {
      const Wrapped = this.wrapped;
      return <Wrapped {...this.props}/>;
    }
  };

  return HOC;
}