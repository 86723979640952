import React, { Component } from 'react';

class ModuleChefs extends Component {
  render() {
    return (
      <section className="moduleChefs">
        <div className="moduleChefs__container">
          <h2 className="t-sectionTitle--large">
            {this.props.header}
          </h2>
          <p className="moduleFeatures__copy t-copy">
            {this.props.body}
          </p>
          {
            this.props.chefs.map((chef, i) => (
              <div key={i} className="moduleChefs__chef">
                <div className="moduleChefs__chef__img">
                  <img src={chef.photo.url} alt={chef.photo.title}/>
                </div>
                <div className="moduleChefs__chef__column">
                  <h2 className="moduleChefs__chef__name t-cardTitle">
                    {chef.name}
                  </h2>
                  <p className="moduleChefs__chef__bio t-copy">
                    {chef.bio}
                  </p>
                </div>
              </div>
            ))
          }
        </div>
      </section>
    )
  }
}

export default ModuleChefs;