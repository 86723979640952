import React, { Component } from 'react';
import Link from '../../components/Link';
import classnames from 'classnames';

class CaseStudyHeader extends Component {
  state = {
    animate: false
  }

  goBack = () => {
    this.props.history.goBack();
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        animate: true
      })
    }, 700);
  }

  render() {
    const heroClasses = classnames(
      'venue__header__bgImg', {
        'venue__header__bgImg--animate': this.state.animate
      }
    )

    return (
      <header className="venue__header">
        <div className="venue__header__container">
          <div className="venue__header__container--inner">
            <svg className="icon-back icon-back--large" onClick={this.goBack}>
              <use xlinkHref="#back-arrow" href="#back-arrow"></use>
            </svg>
            <svg className="icon-back icon-back--small" onClick={this.goBack}>
              <use xlinkHref="#arrow-left-small--red" href="#arrow-left-small--red"></use>
            </svg>
            <h1 className="venue__header__title caseStudy__header__title t-pageTitle">
              {this.props.name}
            </h1>
            <div className="caseStudy__header__related">
              {
                this.props.relatedServices.map((service, i) => (
                  <Link
                    key={i}
                    to={`/what-we-do/${service.route}`}
                    className="caseStudy__header__related__link caseStudy__header__link link link--underline t-tag--large"
                  >
                    {service.name}
                  </Link>
                ))
              }
            </div>
          </div>
        </div>

        <div className="venue__header__img">
          {
            this.props.hero &&
            <div
              className={heroClasses}
              style={{
              backgroundImage: `url(${this.props.hero.url})`
              }}
            />
          }
        </div>

        <div className="venue__overview">
          <div className="venue__overview__container">
            <div className="venue__overview__container--inner caseStudy__overview">
              <div className="caseStudy__overview__column">
                <h2 className="venue__overview__title t-sectionTitle--small">
                  Overview
                </h2>
                <p className="caseStudy__overview__intro t-intro">
                  {this.props.overviewBody}
                </p>
                {
                  this.props.linkCta !== null &&
                  <Link to={this.props.linkUrl} key="external" className="venue__overview__link link link--underline t-tag--large">
                    {this.props.linkCta}
                    <svg className="icon-window">
                      <use xlinkHref="#window" href="#window"></use>
                    </svg>
                  </Link>
                }
              </div>
              <div className="caseStudy__overview__column">
                {
                  this.props.venue !== null &&
                  <div>
                    <h2 className="venue__overview__title t-sectionTitle--small">
                      Venue
                    </h2>
                    <Link to={`/our-venues/${this.props.venue.route}`} className="caseStudy__overview__venue caseStudy__header__link t-tag--large link link--underline">
                      {this.props.venue.name}
                    </Link>
                  </div>
                }
                {
                  this.props.secondaryCalloutTitle !== null &&
                  <div
                    className="caseStudy__overview__secondaryContainer"
                    style={{
                      'marginTop': this.props.venue === null ? '0' : null
                    }}>
                    <h2 className="venue__overview__title t-sectionTitle--small">
                      {this.props.secondaryCalloutTitle}
                    </h2>
                    <span className="caseStudy__overview__secondary t-tag--large">
                      {this.props.secondaryCalloutValue}
                    </span>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default CaseStudyHeader;